import axios from "axios";
import { Status, TrainingStatus } from "shared";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

const getTrainingStatus = () => {
  return axios.get<TrainingStatus>("/training/status").then((res) => res.data);
};

export const useGetTrainingStatus = () => {
  return useQuery({
    queryKey: ["training"],
    queryFn: getTrainingStatus,
  });
};

const postStartTraining = () => {
  return axios.post("/training/start");
};

export const usePostStartTraining = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["training"],
    mutationFn: postStartTraining,
    onSuccess: () => {
      queryClient.setQueryData(["training"], (oldData: TrainingStatus | undefined) => {
        if (oldData) {
          return {
            ...oldData,
            statusTraining: "Active" as Status,
          };
        }
        return undefined;
      });
    }
  });
};