import React, { useMemo } from "react";
import { List } from "@refinedev/mui";
import { IResourceComponentsProps } from "@refinedev/core";
import { useGetTrainingStatus, usePostStartTraining } from "../../api/training.api";
import { Button } from "@mui/material";


export const AiTraining: React.FC<IResourceComponentsProps> = () => {

  const startTrainingQuery = usePostStartTraining();
  const trainingStatusQuery = useGetTrainingStatus();

  const buttonDisabled = useMemo(() => {
    if (trainingStatusQuery.isLoading) {
      return true;
    }

    if (trainingStatusQuery.data?.statusTraining === "Active") {
      return true;
    }

    if (startTrainingQuery.isLoading) {
      return true;
    }

    return false;
  }, [trainingStatusQuery, startTrainingQuery]);

  return (
    <List title="Entrainement de l'IA">
      <Button variant="contained" disabled={buttonDisabled} onClick={() => startTrainingQuery.mutate()}>{
        buttonDisabled ? "L'entrainement est déjà en cours" : "Démarrer l'entrainement"
      }</Button>
    </List>
  );
};
