import { TextField } from "@mui/material";
import { FC } from "react";
import { UseFormRegister } from "react-hook-form";
import { CryptorFormData } from "../utils/cryptor.schema";

interface CryptorDescriptionAIFieldProps {
  register: UseFormRegister<CryptorFormData>;
}

const CryptorDescriptionAIField: FC<CryptorDescriptionAIFieldProps> = ({
  register,
}) => {
  return (
    <TextField
      {...register("descriptionForAI")}
      defaultValue={null}
      margin="normal"
      fullWidth
      InputLabelProps={{ shrink: true }}
      type="text"
      label="Description IA"
      name="descriptionForAI"
      multiline
      minRows={3}
      inputProps={{ maxLength: 150 }}
    />
  );
};

export default CryptorDescriptionAIField;
